import React from 'react';
import Label from '../Label';

const PSD2Warning = () => {
  return <Label title='Notice to users in the European Union, UK, India, Japan and Australia:'>
    Due to PSD2 | 3D Secure and similar requirements, which our payment processor does not support, you may experience a decline of your attempted credit card transaction, particularly for credit cards issued by financial institutions in the European Union, the UK, India, Japan, and Australia. Please work with your issuing bank or consider an alternative form of payment, such as wire, if the problem persists. Thank you and we appreciate your understanding while we work to find a solution to this issue.
  </Label>;
};

export default PSD2Warning;

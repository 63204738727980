import React, { useState, useEffect, useContext } from 'react';
import equal from 'fast-deep-equal/es6';
import PropTypes from 'prop-types';
import { ApplicationContext } from '../../../context/Application.context';
import { validateControlPersonOwnership, validateCorporateEntityOwnership, validDnsSkipCompany } from '../../../constants/cardComplete';
import ApplicationDescription from '../../ApplicationDescription';
import Label from '../../Label';
import EntityList from '../../EntityList';
import SlidePanel from '../../SlidePanel';
import Shareholdings from './Shareholdings';
import { isNumber } from '../../../utils/validations';

const skipText = 'This section is currently not applicable to your company. Select the Next button below to continue with your application.';

const SecurityOwnership = ({ name, company, saveApplication }) => {
  const [state, dispatch] = useContext(ApplicationContext);
  const [slidePane, setSlidePane] = useState(false);
  const [ownerView, setOwnerView] = useState(null);
  const application = state.application;
  const backupApplication = state.backupApplication;
  const isOTCQB = application.typeId === 4;
  const isDNS = application.typeId === 5;
  const peopleList = application.peopleList;
  const corporateEntityList = application && application.corporateEntityList && application.corporateEntityList.map(item => { return { ...item, type: 'entity' }; });
  const securityDetails = application && application.securityDetails;
  const securities = securityDetails && securityDetails.securityList;
  const ownersList = peopleList && peopleList.filter(person => person.isOfficer || person.isDirector || person.isControl).map(item => { return { ...item, type: 'person' }; });
  const secDetailsComplete = state.completeCards['security-details'];
  const secOwnersList = corporateEntityList ? ownersList.concat(corporateEntityList) : ownersList;
  const secDetailCardNum = isOTCQB || isDNS ? 7 : 8;
  const OffDirCpCardNum = isOTCQB || isDNS ? 4 : 5;
  const showIncompletes = state.showIncompletes;
  const isReadOnly = state.readOnly;
  const canSkip = isDNS && application && application.companyInfo && validDnsSkipCompany(application.companyInfo, application.reportingStandard);

  const desc = [
    {
      text: `Provide shareholdings of all officers and directors who beneficially own any class of ${company}’s equity securities and for any other Control Persons who beneficially own more than five percent (5%) of any class of the ${company}’s equity securities.`
    },
    {
      text: 'A Beneficial Shareholder is any person who, directly or indirectly, has or shares the power to vote or dispose of a stock.'
    },
    {
      text: `All Officers, Directors and Control Persons (see Section ${OffDirCpCardNum} to update this list).`
    }
  ];

  useEffect(() => {
    const isPeopleChange = !equal({ ...backupApplication.peopleList }, { ...application.peopleList });
    const isCorpEntityChange = !equal({ ...backupApplication.corporateEntityList }, { ...application.corporateEntityList });

    if (isPeopleChange || isCorpEntityChange) {
      saveApplication();
    }
  }, [state.application]);

  const onUpdate = (data, field) => {
    dispatch({
      type: 'UPDATE_APPLICATION',
      payload: data,
      appField: field,
      card: 'security-ownership'
    });
  };

  const handleEdit = item => {
    setOwnerView(item);
    setSlidePane(true);
  };

  const handleSave = item => {
    const list = item.type === 'person' ? 'peopleList' : 'corporateEntityList';
    const owner = application[list].find(owner => owner.id === item.id);
    let totalEqualOwnerships = (owner.securityOwnerships && item.securityOwnerships) && owner.securityOwnerships.filter((ownership, i) => equal(ownership, item.securityOwnerships[i]));
    if (owner && (owner.isExempt !== item.isExempt) || (item.securityOwnerships && !owner.securityOwnerships) || (owner.securityOwnerships.length !== totalEqualOwnerships.length)) {
      const updateSecOwners = application[list].map(obj => {
        if (obj.id === item.id) {
          delete item.type;
          return item;
        } else {
          return obj;
        }
      });

      onUpdate(updateSecOwners, list);
    }

    setSlidePane(false);
  };

  const handleSaveShareholding = (data) => {
    const type = ownerView.type;
    const list = ownerView.type === 'person' ? 'peopleList' : 'corporateEntityList';
    const updateList = application[list].map(item => {
      if (item.id === ownerView.id) {
        setOwnerView({ ...data, type: type });
        return data;
      } else {
        return item;
      }
    });

    onUpdate(updateList, list);
  };

  const validateComplete = person => {
    const totalSecurities = securities && securities.length;
    const totalCompleteOwnerships = person && person.securityOwnerships && person.securityOwnerships.filter(sec => {
      const security = securities.find(s => s.secId === sec.secId);
      if (person.isControl && !validateControlPersonOwnership(sec)) return false;
      if (person.entityName && !validateCorporateEntityOwnership(sec)) return false;
      return security && isNumber(sec.numberOfShares) && isNumber(sec.percentOfClass);
    }).length;
    return (person && person.isExempt) || (totalSecurities === totalCompleteOwnerships);
  };

  const entityTitle = item => {
    if (item && item.type && item.type === 'person') return `${item.firstName} ${item.lastName}`;
    if (item && item.type && item.type === 'entity') return item.entityName;
  };

  const entitySubTitle = item => {
    if (item.type === 'person') {
      const hasTitles = item && item.title1;

      return hasTitles && item.title1;
    }
  };

  return (
    <div>
      <ApplicationDescription
        title={name}
        descObj={canSkip ? null : desc} />
      {canSkip &&
        <Label className='mtLg' title={skipText} />
      }
      {!canSkip && <>
        {!secDetailsComplete && <Label title={`Section ${secDetailCardNum} (Security Details) needs to be completed before you are able to access Security Ownership.`} />}
        {secDetailsComplete &&
        <EntityList
          title='All Officers, Directors and Control Persons'
          icon={'user'}
          iconComplete={'userComplete'}
          showIncompletes={showIncompletes}
          list={secOwnersList}
          entityTitle={entityTitle}
          validateComplete={validateComplete}
          handleEntityClick={handleEdit} />}
        <SlidePanel
          isOpen={slidePane}
          onClose={() => setSlidePane(false)}
          title={ownerView && entityTitle(ownerView)}
          subTitle={ownerView && entitySubTitle(ownerView)}>
          <Shareholdings
            owner={ownerView}
            securities={securities}
            onSave={handleSave}
            readOnly={isReadOnly}
            showIncompletes={showIncompletes}
            onShareHoldingSave={handleSaveShareholding}
            onShareHoldingUpdate={handleSaveShareholding}
            setSlidePane={setSlidePane} />
        </SlidePanel>
      </>}
    </div>

  );
};

SecurityOwnership.propTypes = {
  name: PropTypes.string,
  company: PropTypes.string
};

export default SecurityOwnership;

import getConfig from '../config';
import React from 'react';
const { IQ_BASE_URL } = getConfig();

export const appNames = {
  OTCQX_US: 'otcqxus',
  OTCQX_BANKS: 'otcqxbanks',
  OTCQX_INTL: 'otcqxintl',
  OTCQB: 'otcqb',
  DNS: 'dns'
};

export const dashboardPaths = {
  START_APPLICATION: 'start-new-application',
  MY_APPLICATIONS: 'my-applications',
  MY_DOCUMENTS: 'my-documents',
  APP: 'app',
  CUF: 'manage-company-profile'
};

export const validDashboardSections = [dashboardPaths.START_APPLICATION, dashboardPaths.MY_APPLICATIONS, dashboardPaths.MY_DOCUMENTS, dashboardPaths.APP];

export const routes = {
  HOME: '/',
  SIGNIN: '/signin',
  SIGNUP: '/signup',
  CONFIRM_ACCOUNT: '/confirm',
  FORGOT_PWD: '/forgot-password',
  DASHBOARD: `/dashboard/:section/:app?`,
  START_APPLICATION: `/dashboard/${dashboardPaths.START_APPLICATION}`,
  MY_APPLICATIONS: `/dashboard/${dashboardPaths.MY_APPLICATIONS}`,
  MY_DOCUMENTS: `/dashboard/${dashboardPaths.MY_DOCUMENTS}`,
  MANAGE_COMPANY_PROFILE: `/dashboard/app/${dashboardPaths.CUF}`,
  APPLICATION: '/application/:appId/:appSection?/:appTab?',
  MICRO_APP: '/app/:app/*',
  COMPANY_INFO: 'company-information',
  ELIGIBILITY_STANDARDS: 'eligibility-standards',
  CHANGE_IN_CONTROL: 'change-in-control',
  REPORTING_STANDARDS: 'reporting-standards',
  FINANCIAL_STANARDS: 'financial-standards',
  OFFICIER_DIR_CONTROL_PERSON: 'officers-directors-control-persons',
  SERVICE_PROVIDERS: 'service-providers',
  SECURITY_DETAILS: 'security-details',
  SECURITY_OWNERSHIP: 'security-ownership',
  LEGAL_DIS_HISTORY: 'legal-disciplinary-history',
  ADDT_SERVICES: 'additional-services',
  DESIGNATE_CONTACTS: 'designate-contacts',
  BG_CHECK_AUTH: 'background-check-authorization',
  SUPP_DOCS: 'supplemental-documents',
  SIGN_SUBMIT: 'sign-submit',
  PAYMENT_SUBMIT: 'payment',
  ACCOUNT_SETTINGS: '/account-settings/:settingsTab',
  CHANGE_PASSWORD: '/account-settings/change-password',
  MANAGE_MFA: '/account-settings/manage-mfa'
};

export const appTypeIds = {
  OTCQX_US: 1,
  OTCQX_BANKS: 2,
  OTCQX_INTL: 3,
  OTCQB: 4,
  DNS: 5
};

export const emails = {
  ISSUER_SERVICES: 'mailto:issuers@otcmarkets.com',
  SALES_TEAM: 'mailto:sales@otcmarkets.com',
  OTCQX_APP_INQUIRIES: 'mailto:otcqxapplications@otcmarkets.com'
};

export const phone_numbers = {
  ISSUER_SERVICES: '212-896-4420'
};

export const entities = {
  NO_NAME: '(No Name Available)',
  CONFIRM_REMOVE_PERSON: 'Are you sure you want to delete this person?',
  CONFIRM_REMOVE_CORP_ENTITY: 'Are you sure you want to delete this corporate entity?',
  CONFIRM_REMOVE_SP: 'Are you sure you want to delete this service provider?',
  CONFIRM_REMOVE_SECURITY: 'Are you sure you want to delete this security?',
  CONFIRM_REMOVE_DOCUMENT: 'Are you sure you want to delete this document?',
  CONFIRM_REMOVE_OWNER: 'Are you sure you want to delete this owner?'
};

export const titles = {
  APP_PARTICIPANTS: 'Application Participants',
  ADD_NEW_PARTICIPANTS: 'Add New Participant',
  EDIT_PHONE_NUMBER: 'Edit Phone Number',
  HELP: 'Help Section'
};

export const storage = {
  AP_USER: 'ap_user',
  AP_USER_REMEMBER: 'ap_remember',
  APP_COMPANIES: 'ap_apps_companies',
  DOCS_COMPANIES: 'ap_docs_companies',
  COUNTRY_CODES: 'ap_cc',
  COUNTRY_CODES_TIME: 'ap_cc_time',
  VIEWED_AGREEMENT: 'ap_viewed_agreement',
  VIEWED_ADDUNDEM: 'ap_viewed_addendum'
};

export const urls = {
  OTC_WEBSITE: 'https://www.otcmarkets.com',
  OTCQXUS_APPLICATION_GUIDE: 'https://www.otcmarkets.com/files/OTCQX_Application_Guide_for_US_Companies.pdf',
  OTCQX_BANKS_APPLICATION_GUIDE: 'https://www.otcmarkets.com/files/OTCQX_Application_Guide_for_US_Banks.pdf',
  OTCQX_INTL_APPLICATION_GUIDE: 'https://www.otcmarkets.com/files/OTCQX_Application_Guide_for_International_Companies.pdf',
  OTCQB_US_APPLICATION_GUIDE: 'https://www.otcmarkets.com/files/OTCQB%20Application%20Guide%20for%20U.S.pdf',
  OTCQB_INTL_APPLICATION_GUIDE: 'https://www.otcmarkets.com/files/OTCQB%20Application%20Guide%20for%20International%20Companies.pdf',
  OTCQX_OTCQB_GUIDELINES: 'https://www.otcmarkets.com/files/OTCQXOTCQBGuidelines.pdf',
  PINK_DNS_GUIDLINES: 'https://www.otcmarkets.com/files/OTCPinkGuidelines.docx',
  OTCQB_GET_STARTED: 'https://www.otcmarkets.com/corporate-services/get-started/otcqb',
  TERMS: 'https://www.otcmarkets.com/terms-of-service',
  PRIVACY_POLICY: 'https://www.otcmarkets.com/privacy-statement',
  PIF_URL: 'https://www.otcmarkets.com/files/Personal_Information_Form.docx',
  STOCK_PROMO_POLICY: 'https://www.otcmarkets.com/files/OTC_Markets_Group_Policy_on_Stock_Promotion.pdf',
  BEST_PRACTICE_ISSUERS: 'https://www.otcmarkets.com/files/Best_Practices_for_Issuers_Stock_Promotion.pdf',
  DILUTION_RISK: 'https://www.otcmarkets.com/files/OTC%20Markets%20Guidance%20on%20Dilution%20Risk.pdf',
  GLOSSARY: 'https://www.otcmarkets.com/glossary',
  IQ_PROFILE: `${IQ_BASE_URL}/myProfile`,
  FACT_SHEET: 'https://www.otcmarkets.com/files/VIC%20Services%20Info%20Sheet.pdf',
  VIC: 'https://www.otcmarkets.com/corporate-services/products/virtual-investor-conferences',
  BLUE_SKY: 'https://www.otcmarkets.com/corporate-services/ir-tools-services/blue-sky',
  PCAOB: 'https://pcaobus.org/oversight/registration/registered-firms',
  GLOSSARY_SHELL: 'https://www.otcmarkets.com/glossary#shell',
  GLOSSARY_IND_DIR: 'https://www.otcmarkets.com/glossary#independent-director',
  GLOSSARY_PUBLIC_FLOAT: 'https://www.otcmarkets.com/glossary#public-float',
  GLOSSARY_BENEFICIAL_SHAREHOLDER: 'https://www.otcmarkets.com/glossary#beneficial-shareholder',
  GLOSSARY_CHANGE_CONTROL: 'https://www.otcmarkets.com/glossary#change-in-control',
  GLOSSARY_NTA: 'https://www.otcmarkets.com/glossary#net-tangible-assets-nta',
  GLOSSARY_TRANSFER_AGENT: 'https://www.otcmarkets.com/glossary#transfer-agent-verified',
  TAVSP: 'https://www.otcmarkets.com/corporate-services/transfer-agent-verified-shares-program',
  CORP_SERV_QX_SPONSORS: 'https://www.otcmarkets.com/corporate-services/otcqx-sponsors',
  OTCQB_STANDARDS: 'https://www.otcmarkets.com/files/OTCQB_Standards.pdf',
  PAYMENT_INFO: 'https://www.otcmarkets.com/corporate-services/payment-information',
  FEE_SCHEDULE: 'https://www.otcmarkets.com/corporate-services/fee-schedule',
  ADDENDUM_CHANGE: 'https://www.otcmarkets.com/files/OTCIQ_Order_Form_Addendum_Change.docx',
  COURT_APPT_CUSTODIAN_EVENT: '/docs/OTCIQ_Order_Form_Addendum_Custodianship.docx',
  REAL_TIME_LEVEL_2: 'https://www.otcmarkets.com/corporate-services/products/real-time-level-2-data',
  DIS_GUIDANCE_BANK_REPORT_COMP: 'https://www.otcmarkets.com/files/Disclosure_Guidelines_for_Bank_Reporting_Companies.docx',
  OTCQB_CERT: 'https://www.otcmarkets.com/files/OTCQBCertificationTemplate.docx',
  QFE: 'https://www.otcmarkets.com/corporate-services/qualified-foreign-exchange',
  LINK_ATS_OVERVIEW: 'https://www.otcmarkets.com/otc-link/overview',
  ISSUER_SERVICE_AGREEMENT: '/files/issuer-services-agreement',
  INITIAL_REVIEW_ADDENDUM: '/files/inital-review-addendum'
};

export const types = {
  PERSONAL: 'PERSONAL',
  SHAREH_LIST: 'SHAREH_LIST',
  DRAFT_QB_CERT: 'DRAFT_QB_CERT',
  NOBO_LIST: 'NOBO_LIST',
  BG_CHECK: 'BG_CHECK',
  BOARD: 'BOARD',
  LETTER_RESIGN: 'LETTER_RESIGN',
  CONTRACTS: 'CONTRACTS',
  ID: 'ID',
  FINANCIAL_REPORT: 'FINANCIAL_REPORT',
  OTHER: 'OTHER',
  LOGO: 'LOGO',
  DSC_CHG_CTRL_EVT: 'DSC_CHG_CTRL_EVT',
  TRANS_JOURNAL: 'TRANS_JOURNAL',
  APP_APPT_CUST: 'APP_APPT_CUST',
  CRT_ORD_GRNT_CUST: 'CRT_ORD_GRNT_CUST'
};

export const appStates = {
  IN_PROGRESS: 'IN_PROG',
  SUBMITTED: 'SUBMITTED',
  CANCELED: 'CANCELED',
  APPROVED: 'APPROVED',
  EXPIRED: 'EXPIRED',
  CLOSED: 'CLOSED'
};

export const awsCodes = {
  SIGN_UP_USER_EXIST: 'UsernameExistsException',
  SIGN_IN_USER_NOT_FOUND: 'UserNotFoundException',
  SIGN_UP_INVALID_PWD: 'InvalidPasswordException'
};

export const errors = {
  CONTACT: <>Contact Issuer Services at {phone_numbers.ISSUER_SERVICES} or <a href={emails.ISSUER_SERVICES}>issuers@otcmarkets.com</a>.</>,
  INCOMPLETE_DATA: 'Incomplete Information. Please enter information for all necessary fields.',
  TRY_AGAIN: <>Try again or contact Issuer Services at {phone_numbers.ISSUER_SERVICES} or <a href={emails.ISSUER_SERVICES}>issuers@otcmarkets.com</a>.</>,
  FILE_UPLOAD: <>Error uploading document. Try again or contact Issuer Services at {phone_numbers.ISSUER_SERVICES} or <a href={emails.ISSUER_SERVICES}>issuers@otcmarkets.com</a>.</>,
  SIGN_UP_INCOMPLETE: 'Incomplete Information. The form is missing some fields. Please enter information for those fields before you are able to create an account.',
  SIGN_UP_EMAIL_MISMATCH: 'Email Doesn’t Match. The email entered in the above fields do not match. Please re-enter email.',
  SIGN_UP_EMAIL_IN_USE: 'Email Already in Use.',
  SIGN_UP_USER_AWS_FAIL: <>Try again or contact Issuer Services at {phone_numbers.ISSUER_SERVICES} or <a href={emails.ISSUER_SERVICES}>issuers@otcmarkets.com</a>.</>,
  SIGN_IN_MFA: 'Wrong Code. The Pin code entered does not match the code from our system.',
  SIGN_IN_USER_NOT_EXIST: 'User Name or Password is not recognized.',
  SIGN_IN_MFA_RESEND: <>Failed to resend code. Try again or contact Issuer Services at {phone_numbers.ISSUER_SERVICES} or <a href={emails.ISSUER_SERVICES}>issuers@otcmarkets.com</a>.</>,
  FORGOT_PWD_INVALID_EMAIL: 'Email address entered is not a valid email.',
  INCOMPLETE_DATA_FIELD: 'Incomplete Data Field. Please enter information to resolve.',
  SUBMIT_MISSING_FIELDS: 'Add a payment type for the Application Fee before you submit this Application.',
  SUBMIT_PAYMENT_FAIL: 'Error submitting your payment.',
  TIMED_OUT: 'Your current session has expired. You will need to log in to your account again. All data before you were logged out has been saved.',
  FILE_MISSING: 'Please Select a file',
  FILE_SIZE: 'File Size Error. The file selected is larger than the file size limit of 20 MB. Please upload a smaller file.',
  FILE_NOT_SUPPORTED: 'File Type Error. The file selected is not one of our approved file types. Please upload a different file.',
  FUTURE_DATE: 'Date cannot be Future Date.',
  MIN_ONE_SHARE_OWNED: 'An owner cannot own zero shares.',
  MIN_ONE_PERCENT_OWNED: 'An owner cannot own zero percent of class.',
  INVALID_CC_NUMBER: 'Invalid Credit Card Number.',
  INVALID_PHONE_NUMBER: 'Invalid Phone Number.',
  INVALID_CHECK_NUMBER: 'Invalid Check Number.',
  CUSIP: 'CUSIPs should be 9 character alpha/numeric.',
  ISIN: 'An ISIN consists of three parts: A two-letter country code, A nine-character alphanumeric national security identifier, A single check digit.',
  SUBMIT_APP_API: <>Payment has been received, however there was a technical issue submitting your application. Contact Issuer Services at {phone_numbers.ISSUER_SERVICES} or <a href={emails.ISSUER_SERVICES}>issuers@otcmarkets.com</a>.</>,
  SUBMIT_APP: <>Payment has been received, however there was a technical issue submitting your application. You can try to submit the application again. If the issue persists you can contact Issuer Services at {phone_numbers.ISSUER_SERVICES} or <a href={emails.ISSUER_SERVICES}>issuers@otcmarkets.com</a>.</>
};

export const mimeTypes = {
  DOC: 'application/msword',
  DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  XLS: 'application/vnd.ms-excel',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  PDF: 'application/pdf',
  CSV: 'text/csv',
  JPG: 'image/jpeg',
  SVG: 'image/svg+xml',
  PNG: 'image/png',
  EPS: 'application/postscript',
  AI: 'application/postscript'
};

export const acceptFiles = {
  DOCUMENTS: '.docx, .doc, .xlsx, .xls, .pdf, .csv',
  IMAGES: '.jpg, .jpeg, .svg, .png, .eps, .ai',
  DOCS_LIST: [mimeTypes.DOC, mimeTypes.DOCX, mimeTypes.XLS, mimeTypes.XLSX, mimeTypes.PDF, mimeTypes.CSV],
  IMG_LIST: [mimeTypes.JPG, mimeTypes.SVG, mimeTypes.PNG, mimeTypes.EPS, mimeTypes.AI]
};

export const reportingStandards = {
  SEC: 'SEC Reporting Standard',
  REGULATION: 'Regulation A Reporting Standard',
  ALTERNATIVE: 'Alternative Reporting Standard',
  BANK: 'U.S. Bank Reporting Standard',
  INTERNATIONAL: 'International Reporting Standard'
};

export const pifReportingStandards = [reportingStandards.SEC, reportingStandards.REGULATION, reportingStandards.BANK, reportingStandards.ALTERNATIVE];

export const changeInCtrl = {
  isChangeInMajority: 'Change in Majority Beneficial Owner',
  isSaleDisAssets: 'Sale or Disposition of All Assets',
  isChangeMajorityDir: 'Change in the Majority of Directors',
  isMerger: 'Merger or Consolidation'
};

export const timezones = {
  NEW_YORK: 'America/New_York'
};

export const countries = {
  US: 'US',
  USA: 'USA',
  UNITED_STATES: 'United States',
  US_OF_A: 'United States of America',
  CA: 'CA',
  CAN: 'CAN',
  CANADA: 'Canada',
  AU: 'AU',
  AUS: 'AUS',
  AUSTRALIA: 'Australia'
};

export const usaStates = {
  CONNECTICUT: 'CT',
  DISTRICT_OF_COLUMBIA: 'DC'
};

export const taxUsaStates = [usaStates.CONNECTICUT, usaStates.DISTRICT_OF_COLUMBIA];

export const financialStandardsQualification = {
  DOES_NOT_QUALIFY: 'doesNotQualify',
  QUALIFIES_US: 'qualifiesForQXUS',
  QUALIFIES_INTL: 'qualifiesForQXIntl'
};

export const applicationFeeMap = new Map([
  [appTypeIds.OTCQX_US, 6000],
  [appTypeIds.OTCQX_BANKS, 6000],
  [appTypeIds.OTCQX_INTL, 6000],
  [appTypeIds.OTCQB, 6000],
  [appTypeIds.DNS, 3500]
]);

export const accountTypeChecking = 'ACH_CHECKINGS';
export const accountTypeSaving = 'ACH_SAVINGS';

export const accountTypes = [
  { id: accountTypeChecking, text: 'Checking' },
  { id: accountTypeSaving, text: 'Saving' }
];

export const paymentTypes = {
  CREDIT: 'CREDIT',
  ACH_CHECKINGS: 'ACH_CHECKINGS',
  ACH_SAVINGS: 'ACH_SAVINGS',
  OFFLINE: 'OFFLINE',
  OFFLINE_CHECK: 'OFFLINE_CHECK',
  OFFLINE_WIRE: 'OFFLINE_WIRE'
};

export const paymentTypesDesc = new Map([
  [paymentTypes.CREDIT, 'Credit Card'],
  [paymentTypes.ACH_CHECKINGS, 'ACH (U.S. Bank)'],
  [paymentTypes.ACH_SAVINGS, 'ACH (U.S. Bank)'],
  [paymentTypes.OFFLINE_CHECK, 'Check'],
  [paymentTypes.OFFLINE_WIRE, 'Wire Transfer']
]);

export const onlinePayments = [paymentTypes.ACH_CHECKINGS, paymentTypes.ACH_SAVINGS, paymentTypes.CREDIT];
export const achPayments = [paymentTypes.ACH_CHECKINGS, paymentTypes.ACH_SAVINGS];
export const offlinePaymentTypes = [paymentTypes.OFFLINE_WIRE, paymentTypes.OFFLINE_CHECK];

export const reducedFeeTypes = {
  UPGRADE_FROM_OTCQB: 'UPGRADE_FROM_OTCQB',
  UPGRADE_FROM_DNS: 'UPGRADE_FROM_DNS',
  REAPPLY_OTCQX: 'REAPPLY_OTCQX',
  REAPPLY_OTCQB: 'REAPPLY_OTCQB'
};

const upgradeDnsTitle = 'Upgrading from DNS';
const upgradeOtcqbTitle = 'Upgrading from OTCQB';
const reapplyOtcqxTitle = 'Reapplying for OTCQX';
const reapplyOtcqbTitle = 'Reapplying for OTCQB';

export const reducedFeesOptionsOtcqx = [{
  id: reducedFeeTypes.UPGRADE_FROM_DNS,
  title: upgradeDnsTitle,
  fee: 2500,
  desc: 'The Company subscribed to OTC Disclosure & News Service (DNS) within the last year. Eligible for a reduced fee.'
}, {
  id: reducedFeeTypes.UPGRADE_FROM_OTCQB,
  title: upgradeOtcqbTitle,
  fee: 0,
  desc: 'The Company is upgrading from OTCQB and has been trading on OTCQB for less than one year. The Application Fee is waived.'
}, {
  id: reducedFeeTypes.REAPPLY_OTCQX,
  title: reapplyOtcqxTitle,
  fee: 0,
  desc: 'The Company is reapplying for OTCQX and has previously paid the Application fee within the last year. The Application fee is waived.'
}
];

export const reducedFeesDescOtcqx = new Map([
  [reducedFeeTypes.UPGRADE_FROM_DNS, upgradeDnsTitle],
  [reducedFeeTypes.UPGRADE_FROM_OTCQB, upgradeOtcqbTitle],
  [reducedFeeTypes.REAPPLY_OTCQX, reapplyOtcqxTitle]
]);

export const reducedFeesOptionsOtcqb = [{
  id: reducedFeeTypes.UPGRADE_FROM_DNS,
  title: upgradeDnsTitle,
  fee: 2500,
  desc: 'The Company subscribed to OTC Disclosure & News Service (DNS) within the last year. Eligible for a reduced fee.'
}, {
  id: reducedFeeTypes.REAPPLY_OTCQB,
  title: reapplyOtcqbTitle,
  fee: 0,
  desc: 'The Company is reapplying for OTCQB and has previously paid the Application fee within the last year. The Application fee is waived.'
}
];

export const reducedFeesDescOtcqb = new Map([
  [reducedFeeTypes.UPGRADE_FROM_DNS, upgradeDnsTitle],
  [reducedFeeTypes.REAPPLY_OTCQB, reapplyOtcqbTitle]
]);

export const usaZipCodeRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const testCusip = '999999999';

export const phoneNumberRegex = /^[+]\d+$/;
export const mfaPhoneNumberRegex = /^[+]?[\d -]+$/;

export const charOrNumRegex = /^[a-zA-Z0-9]+$/;

export const asciiCodes = {

  NUM_ZERO: '0'.charCodeAt(0),
  NUM_NINE: '9'.charCodeAt(0),
  CHAR_BIG_A: 'A'.charCodeAt(0),
  CHAR_BIG_Z: 'Z'.charCodeAt(0),
  CHAR_SMALL_A: 'a'.charCodeAt(0),
  CHAR_SMALL_Z: 'z'.charCodeAt(0),
  CHAR_STAR: '*'.charCodeAt(0),
  CHAR_AT: '@'.charCodeAt(0),
  CHAR_POUND: '#'.charCodeAt(0)

};

export const filingCycles = [
  { id: 'quarter', text: 'Quarterly' },
  { id: 'semiAnnual', text: 'Semiannually' },
  { id: 'annual', text: 'Annually' }
];

export const filingCyclesDesc = new Map([
  ['quarter', 'Quarterly'],
  ['semiAnnual', 'Semiannually'],
  ['annual', 'Annually']
]);

export const applicationMinBidPriceMap = new Map([
  [appTypeIds.OTCQX_US, '0.25'],
  [appNames.OTCQX_US, '0.25'],
  [appTypeIds.OTCQX_BANKS, '1'],
  [appNames.OTCQX_BANKS, '1'],
  [appTypeIds.OTCQX_INTL, '0.25'],
  [appNames.OTCQX_INTL, '0.25'],
  [appTypeIds.OTCQB, '0.01'],
  [appNames.OTCQB, '0.01']
]);

export const noControlPersonText = (percentOwnership = PERCENT_OWNERSHIP_DEFAULT) => {
  return `There are No Additional Control Persons beneficially owning ${percentOwnership}% or more of any class of any equity security of the issuer.`;
};

export const PERCENT_OWNERSHIP_DEFAULT = 5;

export const PERCENT_OWNERSHIP_HIGH = 10;

export const controls = {
  CANCEL: 'Cancel',
  ADD_PAYMENT: 'Add Payment'
};

export const SECURE_PAY_DESC = 'To keep your personal information secure, the payment information below will only be saved for this session.  If you close the application without submitting, you will need to re-enter payment information.';

export const CC_PAY_DESC = 'Please call your bank and allow this charge from OTC Markets Group, Inc. to go through, as some banks require prior authorization.';


const otcQB = {
  'id': 2678,
  'version': 51,
  'typeId': 4,
  'typeDesc': 'OTCQB',
  'status': 'IN_PROG',
  'lastUpdated': 1637181231925,
  'companyInfo': {
    'name': 'Security QB Co.',
    'oldName': 'Jorgelina QB 1',
    'companyAddress': {
      'address1': 'Address 1',
      'city': 'Beverly Hills',
      'state': 'CA',
      'zip': '90210',
      'country': 'US'
    },
    'billingAddress': {
      'address1': 'Address 1',
      'city': 'Beverly Hills',
      'zip': '90210',
      'country': 'AR',
      'countryName': 'Argentina'
    },
    'phoneNumber': '1122334455',
    'companyEmail': 'aguirrejorgelina@gmail.com',
    'companyWebsiteUrl': 'www.company.com',
    'businessDescription': '123456',
    'numberOfEmployees': 1,
    'numberOfEmployeesAsOf': 1634616000000,
    'fiscalYearEndMonth': 12,
    'fiscalYearEndDay': 31,
    'stateOfIncorporation': 'CD',
    'countryOfIncorporation': 'US',
    'yearOfIncorporation': '2013',
    'hasOtherMarketSecurities': false,
    'changeInControl': false,
    'isDelistFromMajorExchange': false
  },
  'eligibilityStandards': {
    'auditedFinancials': true,
    'isShellBlankCheckCompany': false,
    'isBankrupt': false,
    'inGoodStanding': true,
    'hasTwoIndependentDirectors': true,
    'hasAuditComittee': true,
    'hasAuditComitteeDetail': 't',
    'hasPricedQuotation': true
  },
  'reportingStandard': 'U.S. Bank Reporting Standard',
  reportingStandardDetail: 'My deets',
  'peopleList': [
    {
      'id': 35942,
      'firstName': 'Officer 1',
      'middleName': '',
      'lastName': 'LN',
      'phoneCountryCode': 'US',
      'phone': '1122334455',
      'email': 'facundo.baldarena@gmail.com',
      'title1': 'Executive Director',
      'isOfficer': true,
      'isNoneApply': true,
      'isExempt': true,
      'securityOwnerships': [
        {
          'secId': 83090
        }
      ],
      'otherNames': 'Officer 1 LN',
      'birthMonth': 10,
      'birthDay': 19,
      'birthYear': '2021',
      'country': 'AR',
      'state': 'CD',
      'city': 'La Calera',
      'noPIFAvailable': true
    },
    {
      'id': 53314,
      'firstName': 'Chairperson',
      'middleName': '',
      'lastName': 'LN',
      'phoneCountryCode': 'US',
      'phone': '1122334455',
      'email': 'facundo.baldarena@gmail.com',
      'isDirector': true,
      'isChairPerson': true,
      'isNoneApply': true,
      'isExempt': true,
      'isLimitedActivitiesFiveYears': false,
      'isIndependent': false,
      'isAuditCommittee': false,
      'isNominatingCommittee': false,
      'isCompensationCommittee': false,
      'securityOwnerships': [
        {
          'secId': 83090
        }
      ],
      'country': 'AF',
      'noPIFAvailable': true,
      'isDirectorVerified': true
    },
    {
      'id': 42169,
      'firstName': 'Director 1',
      'middleName': '',
      'lastName': 'LN',
      'phoneCountryCode': 'US',
      'phone': '1122334455',
      'email': 'facundo.baldarena@gmail.com',
      'isDirector': true,
      'isChairPerson': false,
      'isExempt': true,
      'isIndependent': true,
      'isAuditCommittee': true,
      'isNominatingCommittee': true,
      'isCompensationCommittee': true,
      'securityOwnerships': [
        {
          'secId': 83090
        }
      ],
      'pifUploadedId': 3740,
      'isDirectorVerified': true
    },
    {
      'id': 21716,
      'firstName': 'Individual 1',
      'middleName': '',
      'lastName': 'LN',
      'isControl': true,
      'isLimitedActivities': true,
      'isNoneApply': false,
      'securityOwnerships': [
        {
          'secId': 83090,
          'numberOfShares': 50,
          'percentOfClass': 0.11,
          'note': '1'
        },
        {
          'secId': 81116,
          'numberOfShares': 500,
          'percentOfClass': 0.22,
          'note': '2'
        }
      ],
      'otherNames': 'Individual 1 LN',
      'birthMonth': 10,
      'birthDay': 19,
      'birthYear': '2021',
      'country': 'CA',
      'state': 'AB',
      'city': 'La Calera',
      'noPIFAvailable': false
    },
    {
      'id': 81116,
      'firstName': 'Beneficial 1',
      'middleName': '',
      'lastName': 'LN',
      'isCorporateEntity': true,
      'isExempt': false
    },
    {
      'id': 20640,
      'firstName': 'Primary Contact. 1',
      'middleName': '',
      'lastName': 'LN',
      'phoneCountryCode': 'US',
      'phone': '1122334455',
      'email': 'facundo.baldarena@gmail.com',
      'title1': 'Company Secretary',
      'isPrimaryContact': true
    },
    {
      'id': 86402,
      'firstName': 'Authorized User 1',
      'middleName': '',
      'lastName': 'LN',
      'phoneCountryCode': 'US',
      'phone': '1122334455',
      'email': 'facundo.baldarena@gmail.com',
      'title1': 'Chief Business Officer',
      'isAuthorizedUser': true
    },
    {
      'id': 58283,
      'firstName': 'Primary Billing Contact. 1',
      'middleName': '',
      'lastName': 'LN',
      'phoneCountryCode': 'US',
      'phone': '1122334455',
      'email': 'facundo.baldarena@gmail.com',
      'title1': 'Chief Accounting Officer',
      'isPrimaryBillingContact': true
    },
    {
      'id': 28876,
      'firstName': 'Additional Billing Contact',
      'middleName': '',
      'lastName': 'LN',
      'phoneCountryCode': 'US',
      'phone': '1122334455',
      'email': 'facundo.baldarena@gmail.com',
      'title1': 'Chief Business Officer',
      'isOtherBillingContact': true
    }
  ],
  'transferAgents': [
    {
      'id': 74387,
      'name': 'Transfer 1',
      'country': 'US',
      'primaryFirstName': 'FN',
      'primaryLastName': 'LN',
      'primaryContactCountryCode': 'US',
      'primaryContactPhone': '1122334455',
      'primaryContactEmail': 'aguirrejorgelina@gmail.com',
      'verifiedSharesAuth': true,
      'isPreExisting': false
    }
  ],
  'auditors': [
    {
      'id': 73070,
      'name': 'Auditor 1',
      'address1': 'Address 1',
      'city': 'Beverly Hills',
      'state': 'CA',
      'zip': '90210',
      'country': 'US',
      'primaryFirstName': 'FN',
      'primaryLastName': 'LN',
      'primaryContactTitle': 'Relationship Manager',
      'primaryContactCountryCode': 'US',
      'primaryContactPhone': '1122334455',
      'primaryContactEmail': 'aguirrejorgelina@gmail.com',
      'isPreExisting': false
    }
  ],
  'securitiesCounsels': [
    {
      'id': 17772,
      'name': 'Securities Counsel 1',
      'address1': 'Address 1',
      'city': 'Beverly Hills',
      'state': 'CA',
      'zip': '90210',
      'country': 'US',
      'primaryFirstName': 'FN',
      'primaryLastName': 'LN',
      'primaryContactCountryCode': 'US',
      'primaryContactPhone': '1122334455',
      'primaryContactEmail': 'aguirrejorgelina@gmail.com',
      'isPreExisting': false
    }
  ],
  'corporateEntityList': [
    {
      'id': 25134,
      'entityName': 'Corporate 1',
      'haveBeneficialOwners': false,
      'reasonForNone': '123',
      'owners': [

      ],
      'securityOwnerships': [
        {
          'secId': 83090,
          'numberOfShares': 150,
          'percentOfClass': 0.22,
          'note': '2'
        },
        {
          'secId': 81116,
          'numberOfShares': 300,
          'percentOfClass': 0.22,
          'note': '2'
        }
      ],
      'address1': 'Address 1',
      'city': 'Beverly Hills',
      'state': 'CA',
      'zip': '90210'
    },
    {
      'id': 51047,
      'entityName': 'Corporate 2',
      'haveBeneficialOwners': true,
      'owners': [
        81116
      ],
      'securityOwnerships': [
        {
          'secId': 83090,
          'numberOfShares': 75,
          'percentOfClass': 0.33,
          'note': '3'
        },
        {
          'secId': 81116,
          'numberOfShares': 50,
          'percentOfClass': 0.22,
          'note': '2'
        }
      ],
      'address1': 'Address 1',
      'city': 'Beverly Hills',
      'state': 'CA',
      'zip': '90210'
    }
  ],
  'securityDetails': {
    'securityList': [
      {
        'secId': 83090,
        'isQXtraded': false,
        'type': 'Common Stock',
        'securityClass': 'A',
        'cusip': '0',
        'authorizedShares': 0,
        'isAuthorizedUnlimited': false,
        'restrictedShares': 1,
        'unrestrictedShares': 0,
        'sharesOutstanding': 1,
        'publicFloat': 0,
        'publicFloatPercentage': 0,
        'sharesAsOfDate': 1634616000000,
        'beneficialShareholders': 0,
        'parValue': 1,
        'unrestrictedSharesAffiliates': 0,
        'isQBtraded': true
      },
      {
        'secId': 81116,
        'isQXtraded': false,
        'type': 'Common Stock',
        'securityClass': 'P',
        'cusip': '0',
        'authorizedShares': 25,
        'isAuthorizedUnlimited': false,
        'restrictedShares': 10,
        'unrestrictedShares': 0,
        'sharesOutstanding': 10,
        'publicFloat': 0,
        'publicFloatPercentage': 0,
        'sharesAsOfDate': 1634616000000,
        'beneficialShareholders': 0,
        'parValue': 1,
        'unrestrictedSharesAffiliates': 0,
        'isQBtraded': true
      }
    ],
    'hasVotingRightsDividends': false
  },
  'legalHistory': {
    'companyLegalHistoryPastTenYears': true,
    'companyOfficerLegalHistoryPastFiveYears': true
  },
  'additionalServices': {
    'isInterestedInVIC': false,
    'isInterestedInBSM': false
  },
  'sectionComplete': 11,
  'totalSections': 13,
  'reportingStandardDetail': 'ok',
  'corporateBroker': [
    {
      'id': 40249,
      'name': 'Corporate Broker',
      'address1': 'Address 1',
      'city': 'Beverly Hills',
      'state': 'CA',
      'zip': '90210',
      'country': 'US',
      'primaryFirstName': 'FN',
      'primaryLastName': 'LN',
      'primaryContactCountryCode': 'US',
      'primaryContactPhone': '1122334455',
      'primaryContactEmail': 'aguirrejorgelina@gmail.com',
      'isPreExisting': false
    }
  ]
};

export default otcQB;
